@import"../../assets/colors/colors.scss";

.root {
    padding: 152px 0px 80px;
}

.images {
    width: 240px;
}

.imageContainer {
    text-align: right;

    @media(max-width:600px) {
        text-align: center;
    }
}

.googleImg {
    width: 160px;
}

.googleContainer {
    margin-bottom: 26px;
}

.bg {
    background: $primary;
    padding: 60px 0px;
}

.headingContainer {
    width: 65%;
    margin: auto;

    @media(max-width:600px) {
        width: 80%;
    }
}