@import'../../assets/colors/colors.scss';

.root {
    padding: 100px 0px 40px;
    margin-top: 40px;
}

.imageContainer {
    @media (max-width: 575px) {
        text-align: center;
        padding-top: 26px;
    }
}

.images {
    width: 40%;

    @media (max-width: 712px) {
        width: 60%;
    }

    @media (max-width: 575px) {
        width: 40%;
        text-align: center;
    }
}

.flexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkPrivacy {
    color: $black;
    font-size: 20px;
    text-decoration: none;
    margin: 0px 12px;

    @media (max-width: 699px) {
        font-size: 14px;
    }

    @media (max-width: 599px) {
        font-size: 12px;
    }

}

.meeraLink {
    color: $primary;
    font-size: 16px;
    margin-left: 6px;

    @media (max-width: 699px) {
        font-size: 14px;
    }

    @media (max-width: 599px) {
        font-size: 12px;
    }

}