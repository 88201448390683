@import"../../assets/colors/colors.scss";

.root {
    padding: 80px 0px;
    // background-color: $white;
}

.images {
    width: 240px;
}

.imageContainer {
    @media(max-width:992px) {
        text-align: center;
        padding-bottom: 50px;
    }
}