* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #eeeeee !important;
  font-family: 'Quicksand', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Libre Baskerville', serif;
}