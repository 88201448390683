@import "../../assets/colors/colors.scss";

.root {
    display: flex;
    padding: 0px 18px;
    align-items: center;
    width: 100%;
    margin: auto;
    justify-content: space-between;
    position: fixed;
    background-color: $white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 999;

    @media(max-width:690px) {
        justify-content: space-between;
        width: 100%;
    }

}

.logo {
    height: 60px;
}

.button {
    background-color: transparent;
    border-style: none;
}

.ul {
    margin: 0;
    padding: 0;

    @media(max-width:690px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        width: 100%;
        padding-bottom: 20px;
        background-color: $white;
    }
}

.ul li {
    display: inline;

    @media(max-width:690px) {
        display: block;
        padding: 6px 0px;
    }
}

.link {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: $white;
    text-decoration: none;
    padding: 0px 16px;
    position: relative;
    color: #333;
}

.menu {
    border-radius: 2px;
    display: block;
    height: 3px;
    margin: 10px;
    transition: all .5s;
}

.menu:nth-child(1) {
    background: $primary;
    width: 16px;
}

.menu:nth-child(2) {
    background: $primary;
}

.menu:nth-child(3) {
    background: $primary;
    width: 28px;
}