@import"../../assets/colors/colors.scss";

.root {
    padding: 80px 0px;
}

.images {
    width: 280px;
}

.imageContainer {
    text-align: center;
}