@import"../../assets/colors/colors.scss";

.root {
    padding-top: 150px;
    padding-bottom: 50px;
    width: 90%;
    margin: auto;
}

.inputContainer {
    margin-bottom: 20px;
}

.button {
    background: $primary;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: $white;
    padding: 0px 6px;
    letter-spacing: 1px;
    margin-top: 40px;
}

.image {
    width: 90%
}